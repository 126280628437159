// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}
.ls-gui-element.ls-bar-timer {
    display: none;
}
/** Replace the width of the container **/
.container {
    width: 1550px;
    @media screen and (max-width: 1550px) {
        width: 100%;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
// @import 'modules/menu';
@import 'modules/menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#main-section {
    h3 {
        font-weight: bold;
        font-size: $font-size-24;
        color: $color-black;
        padding-bottom: 5px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-24;
        color: $color-black;
        margin: 0;
        line-height: 1.5;
    }
    .section-solution {
        display: flex;
        padding-top: 75px;
        .left-side {
            width: 50%;
            display: flex;
            align-items: center;
            padding: 10px 3%;
            background: #006d21;
            .icon {
                margin-right: 25px;
            }
            .text {
                padding-top: 10px;
                h4 {
                    font-weight: 900;
                    font-size: $font-size-46;
                    color: $color-white;
                    line-height: 0.8;
                    text-transform: uppercase;
                }
                h5 {
                    font-weight: 900;
                    font-size: $font-size-32;
                    color: $color-white;
                }
            }
        }
        .right-side {
            width: 50%;
            border: 4px solid #ffd51c;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .section-text-img {
        display: flex;
        .left-side {
            width: 50%;
            padding-top: 50px;
            padding-right: 30px;
            .txt {
                padding-top: 15%;
            }
        }
        .right-side {
            width: 50%;
            
        }
    }

    .section-logo-text {
        padding: 50px 0;
        .img-responsive {
            padding-left: 5%;
        }
        .section-text {
            display: flex;
            .left-side {
                width: 50%;
                margin-right: 5%;
            }
            .right-side {
                width: 50%;
            }
        }
    }
}

#section-carousel {
    background: #000;
    padding: 25px 0;
    
}

/*******************************************************************************
* ÉTUDE DE CAS
*******************************************************************************/

#toolbar.section-gallerie {
    padding: 100px 10%;
    .section-intro {
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-black;
            margin: 0;
            line-height: 1.5;
        }
    }
    .section-etudes {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 35px;
        .item {
            width: 30%;
            padding: 5px 0 50px 0;
            h3 {
                background: #000;
                font-weight: bold;
                font-size: $font-size-24;
                color: $color-white;
                padding: 5px;
            }
            p {
                font-weight: normal;
                font-size: $font-size-24;
                color: $color-black;
                margin: 0;
                line-height: 1.5;
                padding-top: 10px;
            }
            div.img-wrapper {
                position: relative;
                transition: .5s;   
                img {
                    width: 100%;
                }  
            }
            div.overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                visibility: hidden;
                transition: .5s;
                background: rgba(255, 213, 28, 0.6);
            }
            &:hover {
                div.overlay {
                  opacity: 1;
                  visibility: visible;
                  transition: .5s;
                }
            }
        }
    }
}

/*******************************************************************************
* QUINCAILLERIE INDUSTRIELLE
*******************************************************************************/

#section-text-img {
    display: flex;
    align-items: center;
    padding: 25px 0 25px 10%;
    .left-side {
        width: 45%;
        margin-right: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-24;
            color: $color-black;
            padding-bottom: 5px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-black;
            margin: 0;
            line-height: 1.5;
        }
    }
}

#section-title {
    background: url(../images/quincaillerie_section02_parallax_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        font-weight: 900;
        font-size: $font-size-34;
        color: $color-white;
    }
}

#section-list {
    padding: 75px 10%;
    h3 {
        font-weight: bold;
        font-size: $font-size-24;
        color: $color-black;
        padding-bottom: 10px;
    }
    .list {
        display: flex;
        ul {
            list-style-image: url(../images/quincaillerie_section03_carre_noir.jpg);
            padding-left: 25px;
            li {
                font-weight: bold;
                font-size: $font-size-24;
                color: $color-black;
                line-height: 1.5;
                padding-bottom: 5px;
                text-transform: initial;
                padding-left: 25px;
            }
        }
        .left-side {
            width: 49%;
            margin-right: 5%;
        }
    }
}

/*******************************************************************************
* PRODUITS INDUSTRIELS
*******************************************************************************/

#section-intro {
    padding: 75px 10%;
    h3 {
        font-weight: bold;
        font-size: $font-size-24;
        color: $color-black;
        padding-bottom: 5px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-24;
        color: $color-black;
        margin: 0;
        line-height: 1.5;
    }
}

#section-produits {
    padding: 50px 10%;
    display: flex;
    flex-wrap: wrap;
    .bloc {
        width: 33.3333%;
        padding: 25px 10px;
        a.image-popup {
            .img-responsive {
                width: 100%;
                transition: 0.5s;
            }
            &:hover {
                .img-responsive {
                    transform: scale(1.05);
                    transition: 0.5s;
                }
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-22;
            color: $color-black;
            margin: 0;
            line-height: 1.4;
            padding-top: 20px;
        }
    }
}

/*******************************************************************************
* PRODUITS GRAND PUBLIC
*******************************************************************************/




/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    display: flex;
    padding: 100px 10%;
    .left-side {
        width: 50%;
        margin-right: 10%;
        h3 {
            background: #000;
            font-weight: bold;
            font-size: $font-size-24;
            color: $color-white;
            padding: 5px;
            width: 90%;
            margin-bottom: 25px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-22;
            color: $color-black;
            margin: 0;
            line-height: 1.5;
        }
        h4 {
            font-weight: bold;
            font-size: $font-size-28;
            color: $color-black;
        }
        h4.first {
            padding-top: 35px;
        }
        .bloc.last {
            padding-top: 70px;
        }
        .heures-jours {
            display: flex;
            .jours {
                margin-right: 50px;
            }
        }
    }
    .right-side {
        width: 50%;
        #content {
            h3 {
                color: $color-black;
            }
            p {
                a {
                    color: $color-black;
                    font-weight: normal;
                    &:hover {
                        color: #ffd51c;
                    }
                }
            }
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1775px) {
   
nav.module-menu .menu-navbar .wrapper.left a.link, nav.module-menu .menu-navbar .wrapper.right a.link {
    width: 115px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div, nav.module-menu .menu-navbar .wrapper.left .main-menu > ul li div, nav.module-menu .menu-navbar .wrapper.right .main-menu-top > ul li.icon div, nav.module-menu .menu-navbar .wrapper.left .main-menu-top > ul li.icon div, nav.module-menu .menu-navbar .wrapper.right .main-menu-top > ul li.lang div {
    font-size: 0.9rem;
}
}

@media screen and (max-width: 1610px) {
   
nav.module-menu .menu-toggle .word {
    font-size: 1rem;
    font-weight: bold;
}
}

@media screen and (max-width: 1550px) {

.bursts .bursts-container .burst h4 {
    font-size: 2.2vw;
}
.bursts .bursts-container .burst .text-container .border h3 {
    font-size: 1.6rem;
}
#section-text-img {
    padding: 25px 0 25px 5%;
}
#section-list {
    padding: 75px 5%;
}
#toolbar.section-gallerie {
    padding: 100px 5%;
}
}

@media screen and (max-width: 1475px) {
   
nav.module-menu .menu-navbar .wrapper.left a.link, nav.module-menu .menu-navbar .wrapper.right a.link {
    display: none;
}
#section-produits .bloc {
    width: 50%;
}
}

@media screen and (max-width: $size-md-max) {
   
nav.module-menu .menu-navbar {
    height: 100px;
}
#section-title {
    padding: 0 5%;
    text-align: center;
}
#section-coordonnees {
    display: flex;
    padding: 100px 5%;
}
#section-coordonnees .right-side {
    width: 60%;
}
#toolbar.section-gallerie .section-etudes .item {
    width: 50%;
    padding: 5px 15px 50px 0;
}
#toolbar.section-gallerie .section-etudes .item h3 {
    padding: 10px;
}
}

@media screen and (max-width: 1024px) {

.bursts .bursts-container .burst .text-container .border h3 {
    font-size: 1.3rem;
}
#main-section .section-logo-text .img-responsive {
    padding-left: 0;
    margin-left: -35px;
}
#section-text-img {
    padding: 50px 5%;
    flex-direction: column;
}
#section-text-img .left-side {
    width: 100%;
    margin-right: 0;
    padding-bottom: 25px;
}
#section-produits .bloc p {
    padding-top: 10px;
}
}

@media screen and (max-width: $size-sm-max) {

.slideshow-wrap .caption {
    top: 77%;
}
.slideshow-wrap .caption h2 {
    font-size: 1.9rem;
}
#main-section .section-text-img {
    flex-direction: column;
}
#main-section .section-text-img .left-side {
    width: 100%;
    padding: 50px 0;
}
#main-section .section-text-img .left-side .txt {
    padding-top: 50px;
}
#main-section .section-text-img .right-side {
    width: 75%;
    margin: auto;
}
#main-section .section-logo-text .img-responsive {
    padding-left: 0;
    margin: auto;
}
.bursts .bursts-container .burst .text-container .border h3 {
    font-size: 1.1rem;
}
#section-intro, #section-produits {
    padding: 50px 5%;
}
#section-coordonnees {
    padding: 75px 5%; 
}
#section-coordonnees .right-side #map-canvas {
    height: 600px !important
}
#toolbar.section-gallerie {
    padding: 50px 5%;
}
}

@media screen and (max-width: $size-xs-max) {
 
nav.module-menu .menu-navbar.nav-visible .wrapper.left {
    display: none;
}
nav.module-menu .menu-navbar .wrapper.center .logo {
    max-width: 255px;
    padding-left: 15px;
}
nav.module-menu .menu-navbar {
    height: 80px;
}
.slideshow-wrap .caption {
    top: 80%;
    left: 48%;
}
.slideshow-wrap .caption h2 {
    font-size: 2rem;
    text-align: center;
}
#main-section .section-solution {
    padding-top: 50px;
    flex-direction: column;
}
#main-section .section-solution .left-side {
    width: 100%;
    height: 129px;
}
#main-section .section-solution .left-side .icon {
    width: 30%;
}
#main-section .section-solution .right-side, #main-section .section-text-img .right-side, #main-section .section-logo-text .section-text .right-side {
    width: 100%;
}
#main-section .section-logo-text .section-text {
    flex-direction: column;
}
#main-section .section-logo-text .section-text .left-side {
    width: 100%;
    margin-right: 0;
    padding-bottom: 25px;
}
.bursts .bursts-container .burst h4 {
    font-size: 1.3rem;
}
.bursts .bursts-container .burst-left .border {
    padding-left: 0;
}
.bursts .bursts-container .burst-left {
    padding-right: 0;
}
#section-list .list {
    flex-direction: column;
}
#section-list .list .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: -10px;
}
#section-list {
    padding: 50px 5%;
}
#section-produits .bloc {
    width: 100%;
    padding: 25px 0;
}
#section-coordonnees {
    padding: 50px 5%; 
    flex-direction: column;
}
#section-coordonnees .left-side {
    width: 100%;
    margin-right: 0;
}
#section-coordonnees .right-side {
    width: 100%;
    padding-top: 35px;
}
#section-coordonnees .right-side #map-canvas {
    height: 400px !important
}
#toolbar.section-gallerie .section-etudes .item {
    width: 100%;
    padding: 5px 0 25px 0;
}
}

@media screen and (max-width: 360px) {

.slideshow-wrap .caption h2 {
    font-size: 1.8rem;
}
}