.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 90%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-align: center;
            font-size: $font-size-44;
            font-weight: 900;
            color: $color-white;
            text-transform: initial;
            text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }

    }
}
